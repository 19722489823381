@use "/src/styles_query.scss" as mainStyles;
@use "/src/styles_animations.scss";

// image component styles had to be exposed globally for it to work properly application-wide:
.image-loader {
  margin: 0;
  width: 100%;

  & > .image-loader-image {
    border-radius: 5px 5px 0 0;
    width: 100%;
    transition: opacity 200ms ease-in-out;
    aspect-ratio: 1.5/1;
    object-fit: cover;
  }

  &--loading {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOc0bl2EwAGEQKCzxq/BwAAAABJRU5ErkJggg==");
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(1px);
    animation: pulse 1.4s infinite;
    & > .image-loader-image {
      opacity: 0;
    }
  }

  &--loaded {
    background: none;
    filter: none;

    & > .image-loader-image {
      opacity: 1;
    }
  }
}

// These classes were defined here to avoid repeating the same code in 2 different components
.rate-appointment,
.feedback-box {
  position: relative;
  width: 501px;
  display: flex;
  flex-direction: column;
  padding: 40px 40px 20px 40px !important;
  justify-content: center;
  align-items: flex-start;
  gap: 10px 8px;
  border-radius: 0px 30px;
  background: #f4f4f4;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--tabs);
  }

  h1,
  p {
    color: #000000;
    font-size: 18px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    span {
      opacity: 0.5;
      font-weight: 300;
    }
  }

  .mat-mdc-text-field-wrapper {
    --mdc-filled-text-field-active-indicator-height: 0px !important;
    --mdc-filled-text-field-focus-active-indicator-height: 0px !important;
    background-color: #ffffff !important;
    border-radius: 0px 30px 0px 30px !important;
    width: 420px;
    padding: 0px 0px 0px 5px;

    textarea {
      resize: none;
      padding: 5px 10px;
    }

    @include mainStyles.media_query_max(450px) {
      width: 300px;
    }
    @include mainStyles.media_query_max(330px) {
      width: 250px;
    }
  }

  &-button {
    width: 100% !important;
  }

  @include mainStyles.media_query_max(599.98px) {
    width: auto;
    padding: 10px !important;
  }
}

.loading-box {
  position: absolute;
  z-index: 5;
  background-color: #f0f0f0aa;
  width: 100vw;
  height: 90vh;
  top: 93px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
