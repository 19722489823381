/* You can add global styles to this file, and also import other style files */
@use "/src/styles_query.scss" as mainStyles;
@use "/src/styles_utils.scss";

html,
body {
  height: 100%;
}

// Scrollbar global styles
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border: 3px solid var(--background);
  border-radius: 10px;
  background-color: #848484;
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
}

/* Hide Scrollbar on mobile screens */
@include mainStyles.media_query_max(599.98px) {
  ::-webkit-scrollbar {
    display: none !important;
  }

  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif, Roboto, "Helvetica Neue";
  font-style: normal;
  font-weight: normal;

  // Customize autofill styling for material form fields
  input:-webkit-autofill {
    border-radius: 5px;
    -webkit-text-fill-color: var(--accent);
    -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 inset;
    padding-left: 5px;
  }

  .mat-mdc-form-field:hover input:-webkit-autofill:not(:focus) {
    -webkit-box-shadow: 0 0 0px 1000px #ececec inset;
  }

  .mat-mdc-form-field:focus input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #dbdbdb inset;
  }

  // Global styles applied for all formFields:
  .mat-mdc-text-field-wrapper {
    background-color: #ffffff;
    border-radius: 5px;
  }

  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align::before {
    @include mainStyles.media_query_max(599.98px) {
      --mat-form-field-subscript-text-size: 10px;
    }
    --mat-form-field-subscript-text-size: 12px;
  }

  .mat-mdc-form-field input::selection {
    background-color: #561ad922;
  }

  .ngx-mat-tel-input-container {
    .country-selector {
      opacity: 1 !important;
      width: auto;
      min-width: 100px;
      max-width: 110px;
      height: 24px;
      top: 42%;
      .country-selector-code {
        margin-right: 6px;
      }
    }
    input {
      margin-left: 14px !important;
    }
  }

  .ngx-mat-tel-input-mat-menu-panel {
    width: 300px;
    max-height: 400px;
    padding: 0px 10px;
    background-color: #f0f0f0;
    .country-search,
    .country-list-button {
      background-color: #f0f0f0;
      max-width: 250px;
      padding: 10px 0px 10px 10px !important;
    }
    .country-search {
      height: 25px !important;
      margin-bottom: 10px;
    }
  }

  //Customization for the snackbar's panelClasses used in error handling:
  .error-snackbar,
  .warning-snackbar,
  .success-snackbar {
    &.mdc-snackbar .mdc-snackbar__surface {
      border-radius: 36px;
      min-height: fit-content;
    }

    &.mat-mdc-snack-bar-container .mdc-snackbar__label {
      text-align: center;
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      padding: 0;
    }
  }

  .error-snackbar {
    &.mdc-snackbar .mdc-snackbar__surface {
      background: #fff5f5;
      border-left: 3px solid #cd0000;
      box-shadow: 0px 3px 10px 0px #cd000066;
    }
  }

  .warning-snackbar {
    &.mdc-snackbar .mdc-snackbar__surface {
      background: #fffdf6;
      border-left: 3px solid #d89b00;
      box-shadow: 0px 3px 10px 0px #d89b0066;
    }
  }

  .success-snackbar {
    &.mdc-snackbar .mdc-snackbar__surface {
      border-left: 3px solid #148c00;
      background: #f6fff2;
      box-shadow: 0px 3px 10px 0px #148c0066;
    }
  }

  /* Hide Scrollbar For Chrome */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide Scrollbar For IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.main-wrapper {
  box-sizing: border-box;
  width: 100%;
  min-height: 85vh;
  padding: 18px 0 18px 115px;

  @include mainStyles.media_query_max(900px) {
    padding: 7px 0 7px 16px;
  }

  h2 {
    font-size: 18px;
  }
}
// To customize the snackbar's panelClass property it has to be done from the global stylesheet
//Styling for the geolocation notification snackbar:
.primary-snackbar {
  --mdc-snackbar-container-color: var(--accent);
  --mat-snack-bar-button-color: #ffffff;
  border-radius: 5px;
  opacity: 0.9 !important;
}

// Custom dialog component:
/* Apply following styles to the dialog component to customize the dialog container: 

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: transparent;
  border-radius: 5px 30px;
}
*/
.custom-dialog {
  height: -webkit-fill-available;
  height: -moz-available;
  height: fill-available;
  width: fit-content;
  padding: 20px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  border-radius: 5px 30px;
  background-color: #f4f4f4;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  & > p {
    text-align: center;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  &-button {
    color: #ffffff !important;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 600;
  }
}

.initial-render-box {
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  padding-left: 65px;

  &-logo {
    position: relative;
    width: 10%;
    height: 10%;
    background-color: #f0f0f0;

    & > svg {
      width: 100%;
      height: 100%;
    }

    @include mainStyles.media_query_max(850px) {
      width: 20%;
      height: 20%;
    }

    @include mainStyles.media_query_max(450px) {
      width: 25%;
      height: 25%;
    }
  }
  @include mainStyles.media_query_max(900px) {
    padding-left: 0;
  }
}
.animated-logo {
  width: 100%;
  height: 100%;
  cursor: pointer;
  outline: none !important;

  --time: 1800ms;
  #hour-hand {
    transform-origin: 35.5508px 22.9404px;
  }

  #minute-hand {
    transform-origin: 35.5508px 22.9404px;
    animation: clock-ticking steps(40) var(--time) forwards;
    animation-iteration-count: 1;
  }

  &-loop {
    #hour-hand {
      --tick: calc(var(--time) * 60);
      animation: clock-ticking steps(60) var(--tick) infinite;
    }

    #minute-hand {
      animation: clock-ticking steps(40) var(--time) infinite;
    }
  }
}
