/* 
Custom mixings to apply media queries
Import it in component's scss file as follows:  @use '/src/styles.scss' as mainStyles;
then, apply it within any kind of selector:     @include mainStyles.media_query_max(400px) {}
*/
@mixin media_query_max($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin media_query_mobile_height {
  @media (max-width: 960px) and (max-height: 630px) {
    @content;
  }
}
