@use "@angular/material" as mat;

body {
  // Palette colors available as global variables in case they are needed for custom styling
  --background: #f0f0f0;
  --primary: #757575;
  --secondary: #e2e2e2;
  --tertiary: #cccccc;
  --accent: #561ad9;
  --disapprove: #b50202;
  --accept: #18ab00;
  --pending: #0f91a3;
  --tabs: #232323;
  --disabled: #c5c5c5;

  // Background color application-wide
  background-color: var(--background);
}

$dark-text: #000000;
$light-text: #ffffff;

@include mat.core();

$mat-primary: (
  main: #757575,
  lighter: #e2e2e2,
  darker: #232323,
  200: #f0f0f0,
  contrast: (
    main: $light-text,
    lighter: $light-text,
    darker: $light-text,
  ),
);

$mat-accent: (
  main: #561ad9,
  lighter: #e2e2e2,
  darker: #848484,
  200: #561ad9,
  contrast: (
    main: $light-text,
    lighter: $light-text,
    darker: $light-text,
  ),
);

$mat-warn: (
  main: #b50202,
  lighter: #18ab00,
  darker: #0f91a3,
  200: #b50202,
  contrast: (
    main: $light-text,
    lighter: $light-text,
    darker: $light-text,
  ),
);

$typography: mat.m2-define-typography-config(
  $font-family: "Nunito",
);

// Palettes configuration for different scenarios
$primary-palette: mat.m2-define-palette($mat-primary, main, lighter, darker);
$primary-dark-palette: mat.m2-define-palette($mat-primary, darker, main, lighter);

$accent-palette: mat.m2-define-palette($mat-accent, main, lighter, darker);

$warn-disapprove-palette: mat.m2-define-palette($mat-warn, main, lighter, darker);
$warn-accept-palette: mat.m2-define-palette($mat-warn, lighter, main, darker);
$warn-pending-palette: mat.m2-define-palette($mat-warn, darker, main, lighter);

//This is the default theme, which used the disapprove color as warn
$default-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-disapprove-palette,
    ),
    typography: $typography,
  )
);

// This theme uses the tabs color as primary
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $primary-dark-palette,
      accent: $accent-palette,
      warn: $warn-disapprove-palette,
    ),
    typography: $typography,
  )
);

//This theme applies the accept color for the warn option
$accept-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-accept-palette,
    ),
    typography: $typography,
  )
);

//This theme applies the pending color for the warn option
$pending-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-pending-palette,
    ),
    typography: $typography,
  )
);

//Mixing that applies the theme ( colors, density and typography)
@include mat.all-component-themes($default-theme);
/*
Important:
To be able to apply any of the following themes, it is needed to wrap the component with a generic tag (div, span) 
and apply the class to that wrapper, For example:

<span class="accept-warn-theme">
    <button color="warn" mat-raised-button>This button applies the accept color instead of the disapprove</button>
</span>
*/
.accept-warn-theme {
  //Mixing that only changes the colors
  @include mat.all-component-colors($accept-theme);

  & .mat-mdc-raised-button.mat-warn {
    color: $light-text;
  }
}

.pending-warn-theme {
  @include mat.all-component-colors($pending-theme);
}

.dark-theme {
  //Mixing that only changes the colors
  @include mat.all-component-colors($dark-theme);
}
