@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes clock-ticking {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
